/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as DsComponents from '@economist/design-system/dist/umd/common';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { editorTheme } from '../components/playground/theme';
import { DESIGN_SYSTEM_STYLES } from '../constants';
import Seo from '../components/seo/seo';

const PreviewContainer = styled.div`
  background-color: ${(props) => {
    switch (props.background) {
      case 'inverse':
        return 'var(--ds-color-london-20)';
      case 'branded':
        return 'var(--ds-color-economist-red)';
      default:
        return 'var(--ds-color-london-100)';
    }
  }};
  padding: ${(props) => {
    switch (props.background) {
      case 'inverse':
      case 'branded':
        return 'var(--ds-grid-gutter)';
      default:
        return 'var(--ds-grid-gutter) var(--ds-grid-gutter) 0';
    }
  }};
`;

const StyledEditor = styled(LiveEditor)`
  border: var(--ds-border-rule);
  border-radius: 0 0 0.125rem 0.125rem;
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-0);
  line-height: var(--ds-leading-lower);
  margin: var(--ds-grid-gutter);
`;

export const PageTemplatePlayground = ({ pageContext }) => {
  const {
    componentName,
    variant,
    componentCode,
    background,
    style,
    scope,
  } = pageContext;
  let componentScope = {};

  if (scope) {
    // eslint-disable-next-line react/prop-types
    scope.forEach((component) => {
      Object.assign(componentScope, {
        [component]: DsComponents[component],
      });
    });
  } else {
    componentScope = componentName
      ? { [componentName]: DsComponents[componentName] }
      : false;
  }

  return (
    <>
      <Seo title={[componentName, variant, 'Playground']}>
        <link type='text/css' rel='stylesheet' href={DESIGN_SYSTEM_STYLES} />
        <meta name='robots' content='noindex, nofollow' />
      </Seo>
      <LiveProvider
        code={componentCode}
        scope={componentScope}
        language='jsx'
        theme={editorTheme}
      >
        <PreviewContainer background={background}>
          <LivePreview style={style} />
        </PreviewContainer>
        <StyledEditor />
        <LiveError />
      </LiveProvider>
    </>
  );
};

PageTemplatePlayground.defaultProps = {
  pageContext: {
    background: 'default',
    style: {},
    scope: null,
  },
};

PageTemplatePlayground.propTypes = {
  pageContext: PropTypes.shape({
    componentCode: PropTypes.string.isRequired,
    componentName: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    background: PropTypes.oneOf(['default', 'inverse', 'branded']),
    style: PropTypes.shape({}),
    scope: PropTypes.shape({}),
  }),
};

export default PageTemplatePlayground;
